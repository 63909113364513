var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("br"),
      _vm._m(0),
      _c("div", { staticClass: "q-pa-md text-center" }, [
        _c(
          "div",
          { staticClass: "row justify-center" },
          [
            _c("QQButton", {
              attrs: {
                label: "ENTRA!",
                color: "blue-grey",
                icon: "mdi-chart-line",
                size: "xl",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.entraInPiattaformaRami()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("QQDialogError", {
        attrs: {
          is_visible: _vm.has_error,
          title: "Errore",
          message: _vm.error,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Entra in piattaforma Rami"),
      ]),
      _c("hr"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
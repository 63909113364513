var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-dialog",
    {
      attrs: { persistent: "" },
      model: {
        value: _vm.is_visible,
        callback: function ($$v) {
          _vm.is_visible = $$v
        },
        expression: "is_visible",
      },
    },
    [
      _c(
        "q-card",
        [
          _c("q-card-section", [
            _c("div", { staticClass: "text-h6" }, [_vm._v(_vm._s(_vm.title))]),
          ]),
          _c("q-card-section", { staticClass: "q-pt-none" }, [
            _vm._v(" " + _vm._s(_vm.message) + " "),
          ]),
          _c(
            "q-card-actions",
            { attrs: { align: "right" } },
            [
              _c("q-btn", {
                attrs: { flat: "", label: "OK", color: "primary" },
                on: { click: _vm.callback },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }